import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import InviteOthersForm from "./InviteOthersForm";
import {Card, Stack, Typography} from "@mui/material";
import {Alert} from "@mui/lab";

function Invitation() {
    const { token } = useParams();
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [guestId, setGuestId] = useState(null);

    useEffect(() => {
        fetch(`https://eventmanagerapi.jalsasalanakosovo.com/public/api/invitations/accept/${token}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setSuccessMessage("Thank you for your confirmation! Welcome!");
                setGuestId(data.guest_id);
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    }, [token]);

    return (
        <div  className="accept-invitation">
            <Card sx={{ maxWidth: 600 }} variant="outlined">
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5} pl={5} pr={5} pt={5} pb={0}>
                    <Typography variant="h5">Confirm Invitation</Typography>
                </Stack>
                {(successMessage || errorMessage) && (
                    <Stack spacing={5} pl={5} pr={5} pt={5} pb={0} mb={2}>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        {successMessage && <Alert severity="success">{successMessage}</Alert>}
                    </Stack>
                )}
                <Stack direction="column" spacing={5} pl={5} pr={5} pt={5} pb={0}>
                    <Typography variant="h6">Further Guests</Typography>
                    <Typography variant="body1" mt="0 !important">If other people would like to accompany you, please fill out the form below.</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5} pl={5} pr={5} pt={1} pb={0} mb={5}>
                    <InviteOthersForm guestId={guestId} />
                </Stack>
            </Card>
        </div>
    );
}

export default Invitation;
