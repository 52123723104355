import React, { useState } from 'react';
import {Button, ButtonGroup, Stack, TextField} from "@mui/material";
import {Alert} from "@mui/lab";

function InviteOthersForm({ guestId }) {
    const [guests, setGuests] = useState([{ first_name: '', last_name: '' }]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleGuestChange = (index, event) => {
        const newGuests = [...guests];
        newGuests[index][event.target.name] = event.target.value;
        setGuests(newGuests);
    };

    const handleAddGuest = () => {
        setGuests([...guests, { first_name: '', last_name: '' }]);
    };

    const handleRemoveGuest = (index) => {
        const newGuests = guests.filter((_, i) => i !== index);
        setGuests(newGuests);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSuccessMessage(null);
        setErrorMessage(null);

        guests.forEach((guest, index) => {
            fetch(`https://eventmanagerapi.jalsasalanakosovo.com/public/api/guests/${guestId}/invite-others`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(guest),
            })
                .then(response => response.json())
                .then(data => {
                    setSuccessMessage(data.message);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setErrorMessage(error.message);
                });
        });
    };

    return (
        <div>
            {(successMessage || errorMessage) && (
                <Stack spacing={5} pl={5} pr={5} pt={5} pb={0} mb={5}>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                </Stack>
            )}
            <form onSubmit={handleSubmit}>
                {guests.map((guest, index) => (
                    <div key={index}>
                        <ButtonGroup>
                            <Stack p={1}>
                                <TextField label="First Name" name="first_name" value={guest.first_name} onChange={event => handleGuestChange(index, event)} />
                            </Stack>
                            <Stack p={1}>
                                <TextField label="Last Name" name="last_name" value={guest.last_name} onChange={event => handleGuestChange(index, event)} />
                            </Stack>
                            <Stack p={1}>
                                <Button variant="outlined" color="error" onClick={() => handleRemoveGuest(index)}>
                                    Remove
                                </Button>
                            </Stack>

                        </ButtonGroup>
                    </div>
                ))}
                <Stack m={1}>
                    <Button variant="outlined" color="inherit" onClick={handleAddGuest}>
                        Add Another Guest
                    </Button>
                </Stack>
                <Stack m={1}>
                    <Button variant="contained" color="primary" type="submit" >
                        Save
                    </Button>
                </Stack>
            </form>
        </div>
    );
}

export default InviteOthersForm;
