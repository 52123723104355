import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function CheckInBarcode() {
    const [barcode, setBarcode] = useState('');
    const [isScanned, setIsScanned] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const inputRef = useRef(null);

    const handleChange = (event) => {
        setBarcode(event.target.value);
        setIsScanned(false);
        setSuccessMessage('');
        setErrorMessage('');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setIsScanned(true);
        }
    };

    useEffect(() => {
        if (isScanned && barcode !== '') {
            const match = barcode.match(/JALSA-(\d+)/);
            const guestId = match ? Number(match[1]) : null;

            if (guestId !== null) {
                if (navigator.onLine) {
                    const endpoint = 'https://eventmanagerapi.jalsasalanakosovo.com/public/api/check-ins';

                    axios.post(endpoint, { guest_id: guestId })
                        .then(response => {
                            console.log('Check-in successful:', response.data);
                            setSuccessMessage(response.data.message);
                            setErrorMessage('');
                        })
                        .catch(error => {
                            console.error('Check-in failed:', error);
                            setErrorMessage(error.response?.data?.message || 'Check-in failed. Please try again.');
                            setSuccessMessage('');
                        })
                        .finally(() => {
                            setBarcode('');
                            setIsScanned(false);
                            inputRef.current.focus();
                        });
                } else {
                    setErrorMessage('No internet connection. Please try again when online.');
                    setSuccessMessage('');
                    setBarcode('');
                    setIsScanned(false);
                    inputRef.current.focus();
                }
            } else {
                setErrorMessage('Invalid barcode format. Please scan a valid barcode.');
                setSuccessMessage('');
                setBarcode('');
                setIsScanned(false);
                inputRef.current.focus();
            }
        }
    }, [isScanned, barcode]);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className="barcode-wrapper">
            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="check-in-barcode">
                <input
                    type="text"
                    value={barcode}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    ref={inputRef}
                    className="barcode-input"
                />
            </div>
        </div>
    );
}

export default CheckInBarcode;
