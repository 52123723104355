import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Invitation from './Invitation';
import Checkin from "./checkin";
import './App.scss';
import CheckInBarcode from "./checkin-barcode";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/invitation/:token" element={<Invitation />} />
                <Route path="/checkin/" element={<Checkin />} />
                <Route path="/checkin-barcode/" element={<CheckInBarcode />} />
            </Routes>
        </Router>
    );
}

export default App;
