import { Scanner, useContinuousScanner } from "@yudiel/react-qr-scanner";
import React, { useState, useRef, useCallback } from "react";
import {Alert} from "@mui/lab";

function CheckIn() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasScanned, setHasScanned] = useState(false); // New flag for controlling scan

    const scannerRef = useRef(null);

    const { startScanning, stopScanning, scanning } = useContinuousScanner({
        onResult: (result) => {
            if (result && !hasScanned) {
                onScan(result);
            }
        },
        video: {
            ref: scannerRef
        }
    });

    const onScan = useCallback(async (result) => {
        if (result && !hasScanned) {
            setLoading(true);
            setHasScanned(true); // Set the flag to true to prevent further scans

            try {
                const guestId = isNaN(result) ? result : Number(result);

                const response = await fetch('https://eventmanagerapi.jalsasalanakosovo.com/public/api/check-ins', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ guest_id: guestId }),
                });

                const responseData = await response.json();

                if (response.ok) {
                    setSuccess(responseData.message);
                    setError(null);
                } else {
                    setError(responseData.message || 'Check-in failed');
                    setSuccess(null);
                }
            } catch (error) {
                setError(error.message);
                setSuccess(null);
            } finally {
                setLoading(false);
                stopScanning();
            }
        }
    }, [hasScanned, stopScanning]);

    const handleRetry = () => {
        setError(null);
        setLoading(false);
        setHasScanned(false); // Reset the flag
        startScanning();
    };

    const handleNext = () => {
        setSuccess(null);
        setLoading(false);
        setHasScanned(false); // Reset the flag
        startScanning();
    };

    return (
        <div className="check-in">
            {error && (
                <div className="check-in__error-message">
                    <Alert severity="error">Error: {error}</Alert>
                    <button onClick={handleRetry}>Retry</button>
                </div>
            )}
            {success && (
                <div className="check-in__success-message">
                    <Alert severity="success">{success}</Alert>
                    <button onClick={handleNext}>Next</button>
                </div>
            )}
            {(loading || scanning) && (
                <div className="check-in__scanning">
                    <Alert severity="info">Checking...</Alert>
                </div>
            )}
            {!success && !error && !loading && !scanning && (
                <div className="check-in__scanner">
                    <Scanner
                        video={scannerRef.current}
                        onResult={onScan}
                        onError={(error) => console.log(error?.message)}
                        tracker={true}
                    />
                </div>
            )}
        </div>
    );
}

export default CheckIn;
